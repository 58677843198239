import { ResumeStoreState } from '../../../routes/Resume/Store';

import { serializeSlateNodeToHtml } from '../SlateEditor/helper';
import {
  ResumePreviewScorpioBasic,
  ResumePreviewScorpioCertifications,
  ResumePreviewScorpioContact,
  ResumePreviewScorpioCustom,
  ResumePreviewScorpioEducation,
  ResumePreviewScorpioEmployment,
  ResumePreviewScorpioExtra,
  ResumePreviewScorpioHeader,
  ResumePreviewScorpioHeaderLeft,
  ResumePreviewScorpioHeaderRight,
  ResumePreviewScorpioHobby,
  ResumePreviewScorpioLanguages,
  ResumePreviewScorpioLinks,
  ResumePreviewScorpioPageBreak,
  ResumePreviewScorpioProject,
  ResumePreviewScorpioReferences,
  ResumePreviewScorpioSection,
  ResumePreviewScorpioSkills,
  ResumePreviewScorpioSummary,
} from './ResumePreviewScorpio.styles';

export const ResumePreviewScorpio = (state: ResumeStoreState) => {
  const { resume, additionalSections, design } = state;
  const { hasParagraphIndendation = false, paragraphTextAlignment = 'justify' } = design;

  const linkedinUrl = resume?.links?.items?.filter((item) => item?.type === 'linkedin').pop()?.url || '';
  const githubUrl = resume?.links?.items?.filter((item) => item?.type === 'github').pop()?.url || '';
  const fullAddress =
    resume?.contacts?.city && resume?.contacts?.country
      ? `${resume?.contacts?.city}, ${resume?.contacts?.country}`
      : resume?.contacts?.city
      ? resume?.contacts?.city
      : resume?.contacts?.country;
  const summary = resume?.summary ? serializeSlateNodeToHtml(resume?.summary) : '';
  const employmentsItems = resume?.employments?.items?.map((item) => ({
    ...item,
    highlights: serializeSlateNodeToHtml(item?.highlights),
  }));
  const internshipsItems = resume?.internships?.items?.map((item) => ({
    ...item,
    highlights: serializeSlateNodeToHtml(item?.highlights),
  }));
  const educationsItems = resume?.educations?.items?.map((item) => ({
    ...item,
    highlights: serializeSlateNodeToHtml(item?.highlights),
  }));
  const projectsItems = resume?.projects?.items?.map((item) => ({
    ...item,
    highlights: serializeSlateNodeToHtml(item?.highlights),
  }));
  const certificationsItems = resume?.certifications?.items?.map((item) => ({
    ...item,
  }));
  const languagesItems = resume?.languages?.items?.map((item) => ({
    ...item,
  }));
  const hobbyItem = serializeSlateNodeToHtml(resume?.hobbies?.content);
  const skillsItems = resume?.skills?.items?.map((item) => ({
    ...item,
  }));
  const referencesItems = resume?.references?.items?.map((item) => ({
    ...item,
  }));
  const linksItems = resume?.links?.items
    ?.filter((item) => !['linkedin', 'github'].includes(item?.type))
    .map((item) => ({
      ...item,
    }));

  const nodes = [
    <ResumePreviewScorpioHeader>
      <ResumePreviewScorpioHeaderLeft>
        <ResumePreviewScorpioContact
          email={resume?.contacts?.email}
          phone={resume?.contacts?.phone}
          fullAddress={fullAddress}
          linkedinUrl={linkedinUrl}
          githubUrl={githubUrl}
        />
      </ResumePreviewScorpioHeaderLeft>
      <ResumePreviewScorpioHeaderRight>
        <ResumePreviewScorpioBasic
          jobTitle={resume?.basics?.jobTitle}
          firstName={resume?.basics?.firstName}
          lastName={resume?.basics?.lastName}
          name={`${resume?.basics?.firstName} ${resume?.basics?.lastName}`}
        />
      </ResumePreviewScorpioHeaderRight>
    </ResumePreviewScorpioHeader>,
    <ResumePreviewScorpioExtra
      nationality={resume?.basics?.nationality}
      drivingLicense={resume?.basics?.drivingLicense}
      dateOfBirth={resume?.basics?.dateOfBirth}
      placeOfBirth={resume?.basics?.placeOfBirth}
      workRightEU={resume?.basics?.workRightEU}
      workRightUK={resume?.basics?.workRightUK}
      availability={resume?.basics?.availability}
    />,
    <ResumePreviewScorpioSummary
      hasParagraphIndendation={hasParagraphIndendation}
      paragraphTextAlignment={paragraphTextAlignment}
    >
      {summary}
    </ResumePreviewScorpioSummary>,
  ];

  additionalSections?.map(({ key, section }) => {
    if (
      section === 'employments' &&
      resume?.employments?.title &&
      employmentsItems?.length &&
      employmentsItems?.length > 0
    ) {
      nodes.push(
        <>
          <ResumePreviewScorpioSection section="employments">{resume?.employments?.title}</ResumePreviewScorpioSection>
          <ResumePreviewScorpioEmployment item={employmentsItems[0]} />
        </>
      );
      employmentsItems?.slice(1).map((item) => {
        nodes.push(<ResumePreviewScorpioEmployment item={item} />);
      });
    }
    if (
      section === 'internships' &&
      resume?.internships?.title &&
      internshipsItems?.length &&
      internshipsItems?.length > 0
    ) {
      nodes.push(
        <>
          <ResumePreviewScorpioSection section="internships">{resume?.internships?.title}</ResumePreviewScorpioSection>
          <ResumePreviewScorpioEmployment item={internshipsItems[0]} />
        </>
      );
      internshipsItems?.slice(1).map((item) => {
        nodes.push(<ResumePreviewScorpioEmployment item={item} />);
      });
    }
    if (section === 'projects' && resume?.projects?.title && projectsItems?.length && projectsItems?.length > 0) {
      nodes.push(
        <>
          <ResumePreviewScorpioSection section="projects">{resume?.projects?.title}</ResumePreviewScorpioSection>
          <ResumePreviewScorpioProject item={projectsItems[0]} />
        </>
      );
      projectsItems?.slice(1).map((item) => {
        nodes.push(<ResumePreviewScorpioProject item={item} />);
      });
    }
    if (section === 'custom') {
      const title = resume?.[key]?.title;
      const customsItems = resume?.[key]?.items;
      const customsItemsFormatted = customsItems?.map((item) => ({
        ...item,
        highlights: item?.highlights?.map((highlight) => serializeSlateNodeToHtml(highlight)),
      }));
      nodes.push(
        <>
          <ResumePreviewScorpioSection section="custom">{title}</ResumePreviewScorpioSection>
          <ResumePreviewScorpioCustom item={customsItemsFormatted[0]} />
        </>
      );
      customsItemsFormatted?.slice(1).map((item) => {
        nodes.push(<ResumePreviewScorpioCustom item={item} />);
      });
    }
    if (
      section === 'educations' &&
      resume?.educations?.title &&
      educationsItems?.length &&
      educationsItems?.length > 0
    ) {
      const title = resume?.educations?.title || '';
      nodes.push(
        <>
          <ResumePreviewScorpioSection section="educations">{title}</ResumePreviewScorpioSection>
          <ResumePreviewScorpioEducation item={educationsItems[0]} />
        </>
      );
      educationsItems?.slice(1).map((item) => {
        nodes.push(<ResumePreviewScorpioEducation item={item} />);
      });
    }
    if (
      section === 'certifications' &&
      resume?.certifications?.title &&
      certificationsItems?.length &&
      certificationsItems?.length > 0
    ) {
      nodes.push(
        <ResumePreviewScorpioCertifications title={resume?.certifications?.title} items={certificationsItems} />
      );
    }
    if (section === 'languages' && resume?.languages?.title && languagesItems?.length && languagesItems?.length > 0) {
      nodes.push(<ResumePreviewScorpioLanguages title={resume?.languages?.title} items={languagesItems} />);
    }
    if (section === 'hobbies' && resume?.hobbies?.title && languagesItems?.length && languagesItems?.length > 0) {
      nodes.push(<ResumePreviewScorpioHobby title={resume?.hobbies?.title} content={hobbyItem} />);
    }
    if (section === 'skills' && resume?.skills?.title && skillsItems?.length && skillsItems?.length > 0) {
      nodes.push(<ResumePreviewScorpioSkills title={resume?.skills?.title} items={skillsItems} />);
    }
    if (
      section === 'references' &&
      resume?.references?.title &&
      referencesItems?.length &&
      referencesItems?.length > 0
    ) {
      nodes.push(
        <ResumePreviewScorpioReferences
          onDemand={resume?.references?.onDemand}
          title={resume?.references?.title}
          items={referencesItems}
        />
      );
    }
    if (section === 'links' && resume?.links?.title && linksItems?.length && linksItems?.length > 0) {
      nodes.push(<ResumePreviewScorpioLinks title={resume?.links?.title} items={linksItems} />);
    }
    if (section === 'pagebreak') {
      nodes.push(<ResumePreviewScorpioPageBreak />);
    }
  });

  return nodes;
};
