import { ResumeStoreState } from '../../../routes/Resume/Store';

import {
  ResumePreviewAriesBasic,
  ResumePreviewAriesColoredBlock,
  ResumePreviewAriesContact,
  ResumePreviewAriesExtra,
  ResumePreviewAriesSummary,
  ResumePreviewAriesCertifications,
  ResumePreviewAriesHobby,
  ResumePreviewAriesSkills,
  ResumePreviewAriesReferences,
  ResumePreviewAriesLinks,
  ResumePreviewAriesSection,
  ResumePreviewAriesEmployment,
  ResumePreviewAriesCustom,
  ResumePreviewAriesEducation,
  ResumePreviewAriesLanguages,
  ResumePreviewAriesPageBreak,
  ResumePreviewAriesProject,
} from './ResumePreviewAries.styles';
import { serializeSlateNodeToHtml } from '../SlateEditor/helper';

export const ResumePreviewAries = (state: ResumeStoreState) => {
  const { resume, additionalSections, design } = state;
  const { hasParagraphIndendation = false, paragraphTextAlignment = 'justify' } = design;

  const linkedinUrl = resume?.links?.items?.filter((item) => item?.type === 'linkedin').pop()?.url || '';
  const githubUrl = resume?.links?.items?.filter((item) => item?.type === 'github').pop()?.url || '';
  const fullAddress =
    resume?.contacts?.city && resume?.contacts?.country
      ? `${resume?.contacts?.city}, ${resume?.contacts?.country}`
      : resume?.contacts?.city
      ? resume?.contacts?.city
      : resume?.contacts?.country;
  const summary = resume?.summary ? serializeSlateNodeToHtml(resume?.summary) : '';
  const employmentsItems = resume?.employments?.items
    .filter((item) => item?.company && item?.company !== '')
    .map((item) => ({
      ...item,
      highlights: serializeSlateNodeToHtml(item?.highlights),
    }));

  const internshipsItems = resume?.internships?.items
    .filter((item) => item?.company && item?.company !== '')
    .map((item) => ({
      ...item,
      highlights: serializeSlateNodeToHtml(item?.highlights),
    }));
  const educationsItems = resume?.educations?.items
    .filter((item) => item?.institution && item?.institution !== '')
    .map((item) => ({
      ...item,
      highlights: serializeSlateNodeToHtml(item?.highlights),
    }));
  const projectsItems = resume?.projects?.items
    .filter((item) => item?.name && item?.name !== '')
    .map((item) => ({
      ...item,
      highlights: serializeSlateNodeToHtml(item?.highlights),
    }));
  const certificationsItems = resume?.certifications?.items
    .filter((item) => item?.title && item?.title !== '')
    .map((item) => ({
      ...item,
    }));
  const languagesItems = resume?.languages?.items
    .filter((item) => item?.language && item?.language !== '')
    .map((item) => ({
      ...item,
    }));
  const hobbyItem = serializeSlateNodeToHtml(resume?.hobbies?.content);
  const skillsItems = resume?.skills?.items
    .filter((item) => item?.name && item?.name !== '')
    .map((item) => ({
      ...item,
    }));
  const referencesItems = resume?.references?.items
    .filter((item) => item?.name && item?.name !== '')
    .map((item) => ({
      ...item,
    }));
  const linksItems = resume?.links?.items
    ?.filter((item) => !['linkedin', 'github'].includes(item?.type))
    .map((item) => ({
      ...item,
    }));

  const nodes = [
    <ResumePreviewAriesBasic
      jobTitle={resume?.basics?.jobTitle}
      name={`${resume?.basics?.firstName} ${resume?.basics?.lastName}`}
    />,
    <ResumePreviewAriesContact
      email={resume?.contacts?.email}
      phone={resume?.contacts?.phone}
      fullAddress={fullAddress}
      linkedinUrl={linkedinUrl}
      githubUrl={githubUrl}
    />,
    <ResumePreviewAriesColoredBlock />,
    ...(resume?.basics?.isOpen
      ? [
          <ResumePreviewAriesExtra
            nationality={resume?.basics?.nationality}
            drivingLicense={resume?.basics?.drivingLicense}
            dateOfBirth={resume?.basics?.dateOfBirth}
            placeOfBirth={resume?.basics?.placeOfBirth}
            workRightEU={resume?.basics?.workRightEU}
            workRightUK={resume?.basics?.workRightUK}
            availability={resume?.basics?.availability}
          />,
        ]
      : []),
    ,
    <ResumePreviewAriesSummary
      hasParagraphIndendation={hasParagraphIndendation}
      paragraphTextAlignment={paragraphTextAlignment}
    >
      {summary}
    </ResumePreviewAriesSummary>,
  ];

  additionalSections?.map(({ key, section }) => {
    if (
      section === 'employments' &&
      resume?.employments?.title &&
      employmentsItems?.length &&
      employmentsItems?.length > 0
    ) {
      nodes.push(
        <>
          <ResumePreviewAriesSection>{resume?.employments?.title}</ResumePreviewAriesSection>
          <ResumePreviewAriesEmployment item={employmentsItems[0]} />
        </>
      );
      employmentsItems?.slice(1).map((item) => {
        nodes.push(<ResumePreviewAriesEmployment item={item} />);
      });
    }
    if (
      section === 'internships' &&
      resume?.internships?.title &&
      internshipsItems?.length &&
      internshipsItems?.length > 0
    ) {
      nodes.push(
        <>
          <ResumePreviewAriesSection>{resume?.internships?.title}</ResumePreviewAriesSection>
          <ResumePreviewAriesEmployment item={internshipsItems[0]} />
        </>
      );
      internshipsItems?.slice(1).map((item) => {
        nodes.push(<ResumePreviewAriesEmployment item={item} />);
      });
    }
    if (section === 'projects' && resume?.projects?.title && projectsItems?.length && projectsItems?.length > 0) {
      nodes.push(
        <>
          <ResumePreviewAriesSection>{resume?.projects?.title}</ResumePreviewAriesSection>
          <ResumePreviewAriesProject item={projectsItems[0]} />
        </>
      );
      projectsItems?.slice(1).map((item) => {
        nodes.push(<ResumePreviewAriesProject item={item} />);
      });
    }
    if (section === 'custom') {
      const title = resume?.[key]?.title;
      const customsItems = resume?.[key]?.items;
      const customsItemsFormatted = customsItems?.map((item) => ({
        ...item,
        highlights: item?.highlights?.map((highlight) => serializeSlateNodeToHtml(highlight)),
      }));

      nodes.push(
        <>
          <ResumePreviewAriesSection>{title}</ResumePreviewAriesSection>
          <ResumePreviewAriesCustom item={customsItemsFormatted[0]} />
        </>
      );
      customsItemsFormatted?.slice(1).map((item) => {
        nodes.push(<ResumePreviewAriesCustom item={item} />);
      });
    }
    if (
      section === 'educations' &&
      resume?.educations?.title &&
      educationsItems?.length &&
      educationsItems?.length > 0
    ) {
      const title = resume?.educations?.title || '';
      nodes.push(
        <>
          <ResumePreviewAriesSection>{title}</ResumePreviewAriesSection>
          <ResumePreviewAriesEducation item={educationsItems[0]} />
        </>
      );
      educationsItems?.slice(1).map((item) => {
        nodes.push(<ResumePreviewAriesEducation item={item} />);
      });
    }
    if (
      section === 'certifications' &&
      resume?.certifications?.title &&
      certificationsItems?.length &&
      certificationsItems?.length > 0
    ) {
      nodes.push(
        <ResumePreviewAriesCertifications title={resume?.certifications?.title} items={certificationsItems} />
      );
    }
    if (section === 'languages' && resume?.languages?.title && languagesItems?.length && languagesItems?.length > 0) {
      nodes.push(<ResumePreviewAriesLanguages title={resume?.languages?.title} items={languagesItems} />);
    }
    if (section === 'hobbies' && resume?.hobbies?.title && languagesItems?.length && languagesItems?.length > 0) {
      nodes.push(<ResumePreviewAriesHobby title={resume?.hobbies?.title} content={hobbyItem} />);
    }
    if (section === 'skills' && resume?.skills?.title && skillsItems?.length && skillsItems?.length > 0) {
      nodes.push(<ResumePreviewAriesSkills title={resume?.skills?.title} items={skillsItems} />);
    }
    if (
      section === 'references' &&
      resume?.references?.title &&
      referencesItems?.length &&
      referencesItems?.length > 0
    ) {
      nodes.push(
        <ResumePreviewAriesReferences
          onDemand={resume?.references?.onDemand}
          title={resume?.references?.title}
          items={referencesItems}
        />
      );
    }
    if (section === 'links' && resume?.links?.title && linksItems?.length && linksItems?.length > 0) {
      nodes.push(<ResumePreviewAriesLinks title={resume?.links?.title} items={linksItems} />);
    }
    if (section === 'pagebreak') {
      nodes.push(<ResumePreviewAriesPageBreak />);
    }
  });

  return nodes;
};
