import { createGlobalStyle } from 'styled-components';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

import { DesignDensity, DesignTemplateName, DesignColorPalette, DesignFontFamily } from '../../../generated/types';
import { getColorPalette } from '../../../helper/colorPalette';
import { ResumeStoreState } from '../../../routes/Resume/Store';

import { ResumePreviewAries } from './ResumePreviewAries';
import {
  Container,
  InnerContainer,
  Page,
  PageBody,
  PageContainer,
  PageNavigator,
  PageNavigatorActive,
  PageSidebar,
  PagesList,
  SectionContainer,
  SectionSize,
} from './ResumePreview.styles';
import { ChevronLeftIcon, ChevronRightIcon } from '../../atoms/Icons';
import { IconButton } from '../../atoms/Icons/Icon';
import { ResumePreviewTaurus } from './ResumePreviewTaurus';
import { ResumePreviewCancer } from './ResumePreviewCancer';
import { ErrorBoundary } from '../ErrorBoundary';
import { Colors } from '../../../styles/colors';
import { ResumePreviewVirgo } from './ResumePreviewVirgo';
import { ResumePreviewLibra } from './ResumePreviewLibra';
import { ResumePreviewScorpio } from './ResumePreviewScorpio';

const PAGE_HEIGHT = 1030;

export const ResumePreview = ({
  state,
  isOpen,
  hasNavigation = true,
  isAnimated = false,
}: {
  state: ResumeStoreState;
  isOpen?: boolean;
  hasNavigation?: boolean;
  isAnimated?: boolean;
}) => {
  const interval = useRef<ReturnType<typeof setInterval>>();
  const [page, setPage] = useState<number>(0);
  const [maxPage, setMaxPage] = useState<number>(0);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (!isAnimated) return;
    let i = 1;
    interval.current = setInterval(() => {
      i = i + 1;
      setCounter(i);
    }, 5000);
    return () => {
      clearInterval(interval.current);
      interval.current = undefined;
    };
  }, []);

  function handleNextPage() {
    setPage((prev) => Math.min(prev + 1, maxPage));
  }

  function handlePrevPage() {
    setPage((prev) => Math.max(prev - 1, 0));
  }

  if (!state) return null;

  const { design } = state;
  if (!design) return null;

  const templates = [
    DesignTemplateName.Aries,
    DesignTemplateName.Virgo,
    DesignTemplateName.Taurus,
    DesignTemplateName.Cancer,
    DesignTemplateName.Libra,
  ];
  const index = counter % templates.length;
  const overrideTemplate = templates[index];

  const {
    templateName = DesignTemplateName.Aries,
    colorPalette = DesignColorPalette.Default,
    density = DesignDensity.Normal,
    fontFamily = DesignFontFamily.Palatino,
    hasLastModifiedDate,
  } = design;

  const {
    primaryColor,
    secondaryColor,
    contrastColor,
    backgroundColor,
    textColor,
    textColorLight,
    headerColor,
    sidebarBackgroundColor,
    sidebarHeaderColor,
    sidebarTextColor,
  } = getColorPalette(colorPalette);

  const templateNameOverride = isAnimated ? overrideTemplate : templateName;

  const { fontSize, lineHeight } = getTypographyOptions(density, fontFamily, templateNameOverride);

  const GlobalStyles = createGlobalStyle`
    body {
      margin: 0;
      overflow: hidden;
      box-sizing: border-box;
    }
    *, *::before, *::after {
      box-sizing: border-box;
    }
    html {
      --design-font-family: ${fontFamily};
      --design-font-size: ${fontSize};
      --design-line-height: ${lineHeight};
      --design-primary-color: ${primaryColor};
      --design-secondary-color: ${secondaryColor};
      --design-contrast-color: ${contrastColor};
      --design-background-color: ${backgroundColor};
      --design-header-color: ${headerColor};
      --design-text-color: ${textColor};
      --design-text-color-light: ${textColorLight};
      --design-sidebar-background-color: ${sidebarBackgroundColor};
      --design-sidebar-text-color: ${sidebarHeaderColor};
      --design-sidebar-header-color: ${sidebarTextColor};
    }
  `;

  let headerNodes: React.ReactNode[] = [];
  let sidebarNodes: React.ReactNode[] = [];
  let mainNodes: React.ReactNode[] = [];
  switch (templateNameOverride) {
    case 'ARIES':
      headerNodes = ResumePreviewAries(state);
      break;
    case 'TAURUS':
      headerNodes = ResumePreviewTaurus(state);
      break;
    // case 'GEMINI':
    //   Node = ResumePreviewCancer;
    //   break;
    case 'CANCER':
      headerNodes = ResumePreviewCancer(state).headerNodes;
      sidebarNodes = ResumePreviewCancer(state).sidebarNodes;
      mainNodes = ResumePreviewCancer(state).mainNodes;
      break;
    case 'VIRGO':
      headerNodes = ResumePreviewVirgo(state);
      break;
    case 'LIBRA':
      headerNodes = ResumePreviewLibra(state);
      break;
    case 'SCORPIO':
      headerNodes = ResumePreviewScorpio(state);
      break;
    default:
      mainNodes = ResumePreviewAries(state);
  }
  const key =
    templateName.toLowerCase() +
    '-' +
    colorPalette.toLowerCase() +
    '-' +
    density.toLowerCase() +
    '-' +
    fontFamily.toLowerCase();

  return (
    <Container>
      <GlobalStyles />
      <motion.div
        key={key}
        transition={{ duration: 5, repeat: Infinity }}
        animate={{ opacity: 1 }}
        initial={{ opacity: isAnimated ? 0 : 1 }}
        exit={{ opacity: 1 }}
      >
        <InnerContainer $page={page > maxPage ? maxPage : page}>
          <Pages
            headerElements={headerNodes}
            sidebarElements={sidebarNodes}
            mainElements={mainNodes}
            setMaxPage={setMaxPage}
            key={key}
            hasLastModifiedDate={hasLastModifiedDate}
          />
        </InnerContainer>
      </motion.div>
      {maxPage > 0 && hasNavigation && (
        <PageNavigator>
          <IconButton onClick={handlePrevPage}>
            <ChevronLeftIcon size={2} color={page === 0 ? Colors.GreyDarkest : Colors.Primary} />
          </IconButton>
          <PageNavigatorActive>{page + 1}</PageNavigatorActive>/ {maxPage + 1}
          <IconButton onClick={handleNextPage}>
            <ChevronRightIcon size={2} color={page === maxPage ? Colors.GreyDarkest : Colors.Primary} />
          </IconButton>
        </PageNavigator>
      )}
    </Container>
  );
};

const Pages = ({ headerElements, sidebarElements, mainElements, setMaxPage, hasLastModifiedDate }: any) => {
  const [elementHeights, setElementHeights] = useState<any>({});

  const updateElementHeight = (index: string, height: number) => {
    setElementHeights((prevHeights) => {
      return {
        ...prevHeights,
        [index]: height,
      };
    });
  };

  const pages: any = [[]];
  const pageHeight = PAGE_HEIGHT;
  let pageIndex = 0;
  let currentHeight = 0;

  try {
    headerElements?.forEach((element: any, elementIndex: number) => {
      const elementHeight = elementHeights[`header-${elementIndex}`];
      const displayName = element?.type?.displayName || '';
      if (displayName === 'PageBreak' || currentHeight + elementHeight > pageHeight) {
        pageIndex += 1;
        currentHeight = elementHeight;
        pages[pageIndex] = [];
        pages[pageIndex].push({
          element,
          elementIndex: `header-${elementIndex}`,
          type: 'header',
          elementHeight,
          currentHeight,
        });
      } else {
        currentHeight = currentHeight + elementHeight;
        pages[pageIndex].push({
          element,
          elementIndex: `header-${elementIndex}`,
          type: 'header',
          elementHeight,
          currentHeight,
        });
      }
    });

    const headerHeight = currentHeight;
    mainElements.forEach((element: any, elementIndex: number) => {
      const elementHeight = elementHeights[`main-${elementIndex}`];
      const displayName = element?.type?.displayName || '';
      if (displayName === 'PageBreak' || currentHeight + elementHeight > pageHeight) {
        pageIndex += 1;
        pages[pageIndex] = [];
        pages[pageIndex].push({
          element,
          elementIndex: `main-${elementIndex}`,
          type: 'main',
          elementHeight,
          currentHeight,
        });
        currentHeight = elementHeight;
      } else {
        currentHeight = currentHeight + elementHeight;
        pages[pageIndex].push({
          element,
          elementIndex: `main-${elementIndex}`,
          type: 'main',
          elementHeight,
          currentHeight,
        });
      }
    });
    let sidebarPageIndex = 0;
    currentHeight = headerHeight;
    sidebarElements?.forEach((element: any, elementIndex: number) => {
      const elementHeight = elementHeights[`sidebar-${elementIndex}`];
      if (currentHeight + elementHeight > pageHeight) {
        sidebarPageIndex += 1;
        if (!pages[sidebarPageIndex]) pages[sidebarPageIndex] = [];
        pages[sidebarPageIndex].push({
          element,
          elementIndex: `sidebar-${elementIndex}`,
          type: 'sidebar',
          elementHeight,
          currentHeight,
        });
        currentHeight = elementHeight;
      } else {
        currentHeight = currentHeight + elementHeight;
        pages[sidebarPageIndex].push({
          element,
          elementIndex: `sidebar-${elementIndex}`,
          type: 'sidebar',
          elementHeight,
          currentHeight,
        });
      }
    });
    setMaxPage(pageIndex);
  } catch (error) {
    console.error('error', error);
  }

  return (
    <PagesList>
      {/* <pre>{JSON.stringify(elementHeights, null, 2)}</pre> */}
      {pages.map((elements: any, pageIndex: number) => {
        // console.log('pages', pages);
        // const hasSidebar = elements.some((element: any) => element.type === 'sidebar');
        // console.log('hasSidebar', hasSidebar);
        return (
          <Page key={pageIndex}>
            {elements
              .filter((element: any) => element.type === 'header')
              .map(({ element, elementIndex, type }: any) => {
                return (
                  <Section
                    key={elementIndex}
                    updateElementHeight={(height: number) => updateElementHeight(elementIndex, height)}
                  >
                    {element}
                  </Section>
                );
              })}
            <PageContainer>
              <PageSidebar>
                {elements
                  .filter((element: any) => element.type === 'sidebar')
                  .map(({ element, elementIndex, type }: any) => {
                    return (
                      <Section
                        key={elementIndex}
                        updateElementHeight={(height: number) => updateElementHeight(elementIndex, height)}
                      >
                        {element}
                      </Section>
                    );
                  })}
              </PageSidebar>
              <PageBody>
                {elements
                  .filter((element: any) => element.type === 'main')
                  .map(({ element, elementIndex, type }: any) => {
                    return (
                      <Section
                        key={elementIndex}
                        updateElementHeight={(height: number) => updateElementHeight(elementIndex, height)}
                      >
                        {element}
                      </Section>
                    );
                  })}
              </PageBody>
            </PageContainer>
            {hasLastModifiedDate ? <LastModified /> : null}
          </Page>
        );
      })}
    </PagesList>
  );
};

const LastModified = () => {
  return (
    <div
      style={{
        position: 'absolute',
        bottom: 0,
        right: '32%',
        padding: '1rem',
        fontSize: '1rem',
        color: 'var(--design-secondary-color)',
      }}
    >
      Last modified on {new Date().toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })}
    </div>
  );
};

const Section = ({ children, updateElementHeight }: any) => {
  const elementRef = useRef(null);
  // const [height, setHeight] = useState<number>(0);

  useLayoutEffect(() => {
    if (elementRef.current) {
      const height = elementRef.current.clientHeight || 100;
      // setHeight(height);
      updateElementHeight(height);
    }
  }, [children]);

  return (
    <SectionContainer ref={elementRef}>
      {/* <SectionSize>{height}</SectionSize> */}
      <ErrorBoundary message="ResumePreview/Page/Section">{children}</ErrorBoundary>
    </SectionContainer>
  );
};

function getTypographyOptions(
  density: DesignDensity,
  fontFamily: DesignFontFamily,
  templateName: DesignTemplateName = DesignTemplateName.Aries
): { fontSize: string; lineHeight: string } {
  let fontSize = '13pt';
  let lineHeight = '12pt';
  let scale = 1;
  switch (fontFamily) {
    case DesignFontFamily.Palatino:
      scale = 0.95; // OK
      break;
    case DesignFontFamily.Helvetica:
      scale = 0.945;
      break;
    case DesignFontFamily.Courier:
      scale = 0.9;
      break;
  }

  if (templateName === DesignTemplateName.Taurus) {
    switch (fontFamily) {
      case DesignFontFamily.Palatino:
        scale = 1.015; // OK
        break;
      case DesignFontFamily.Helvetica:
        scale = 1;
        break;
      case DesignFontFamily.Courier:
        scale = 0.85;
        break;
    }
  }

  switch (density) {
    case DesignDensity.Dense:
      fontSize = `${10 * scale}pt`;
      lineHeight = '10pt';
      break;
    case DesignDensity.Normal:
      fontSize = `${12 * scale}pt`;
      lineHeight = fontFamily === DesignFontFamily.Courier ? '12pt' : '14pt';
      break;
    case DesignDensity.Loose:
      fontSize = `${14 * scale}pt`;
      lineHeight = '14pt';
      break;
  }

  return { fontSize, lineHeight };
}
