import { ResumeStoreState } from '../../../routes/Resume/Store';

import { serializeSlateNodeToHtml } from '../SlateEditor/helper';
import {
  ResumePreviewLibraBasic,
  ResumePreviewLibraCertifications,
  ResumePreviewLibraContact,
  ResumePreviewLibraCustom,
  ResumePreviewLibraEducation,
  ResumePreviewLibraEmployment,
  ResumePreviewLibraExtra,
  ResumePreviewLibraHeader,
  ResumePreviewLibraHeaderLeft,
  ResumePreviewLibraHeaderRight,
  ResumePreviewLibraHobby,
  ResumePreviewLibraLanguages,
  ResumePreviewLibraLinks,
  ResumePreviewLibraPageBreak,
  ResumePreviewLibraProject,
  ResumePreviewLibraReferences,
  ResumePreviewLibraSection,
  ResumePreviewLibraSkills,
  ResumePreviewLibraSummary,
} from './ResumePreviewLibra.styles';

export const ResumePreviewLibra = (state: ResumeStoreState) => {
  const { resume, additionalSections, design } = state;
  const { hasParagraphIndendation = false, paragraphTextAlignment = 'justify' } = design;

  const linkedinUrl = resume?.links?.items?.filter((item) => item?.type === 'linkedin').pop()?.url || '';
  const githubUrl = resume?.links?.items?.filter((item) => item?.type === 'github').pop()?.url || '';
  const fullAddress =
    resume?.contacts?.city && resume?.contacts?.country
      ? `${resume?.contacts?.city}, ${resume?.contacts?.country}`
      : resume?.contacts?.city
      ? resume?.contacts?.city
      : resume?.contacts?.country;
  const summary = resume?.summary ? serializeSlateNodeToHtml(resume?.summary) : '';
  const employmentsItems = resume?.employments?.items?.map((item) => ({
    ...item,
    highlights: serializeSlateNodeToHtml(item?.highlights),
  }));
  const internshipsItems = resume?.internships?.items?.map((item) => ({
    ...item,
    highlights: serializeSlateNodeToHtml(item?.highlights),
  }));
  const educationsItems = resume?.educations?.items?.map((item) => ({
    ...item,
    highlights: serializeSlateNodeToHtml(item?.highlights),
  }));
  const projectsItems = resume?.projects?.items?.map((item) => ({
    ...item,
    highlights: serializeSlateNodeToHtml(item?.highlights),
  }));
  const certificationsItems = resume?.certifications?.items?.map((item) => ({
    ...item,
  }));
  const languagesItems = resume?.languages?.items?.map((item) => ({
    ...item,
  }));
  const hobbyItem = serializeSlateNodeToHtml(resume?.hobbies?.content);
  const skillsItems = resume?.skills?.items?.map((item) => ({
    ...item,
  }));
  const referencesItems = resume?.references?.items?.map((item) => ({
    ...item,
  }));
  const linksItems = resume?.links?.items
    ?.filter((item) => !['linkedin', 'github'].includes(item?.type))
    .map((item) => ({
      ...item,
    }));

  const nodes = [
    <ResumePreviewLibraHeader>
      <ResumePreviewLibraHeaderLeft>
        <ResumePreviewLibraContact
          email={resume?.contacts?.email}
          phone={resume?.contacts?.phone}
          fullAddress={fullAddress}
          linkedinUrl={linkedinUrl}
          githubUrl={githubUrl}
        />
        <ResumePreviewLibraExtra
          nationality={resume?.basics?.nationality}
          drivingLicense={resume?.basics?.drivingLicense}
          dateOfBirth={resume?.basics?.dateOfBirth}
          placeOfBirth={resume?.basics?.placeOfBirth}
          workRightEU={resume?.basics?.workRightEU}
          workRightUK={resume?.basics?.workRightUK}
          availability={resume?.basics?.availability}
        />
      </ResumePreviewLibraHeaderLeft>
      <ResumePreviewLibraHeaderRight>
        <ResumePreviewLibraBasic
          jobTitle={resume?.basics?.jobTitle}
          firstName={resume?.basics?.firstName}
          lastName={resume?.basics?.lastName}
          name={`${resume?.basics?.firstName} ${resume?.basics?.lastName}`}
        />
      </ResumePreviewLibraHeaderRight>
    </ResumePreviewLibraHeader>,
    <ResumePreviewLibraSummary
      hasParagraphIndendation={hasParagraphIndendation}
      paragraphTextAlignment={paragraphTextAlignment}
    >
      {summary}
    </ResumePreviewLibraSummary>,
  ];

  additionalSections?.map(({ key, section }) => {
    if (
      section === 'employments' &&
      resume?.employments?.title &&
      employmentsItems?.length &&
      employmentsItems?.length > 0
    ) {
      nodes.push(
        <>
          <ResumePreviewLibraSection section="employments">{resume?.employments?.title}</ResumePreviewLibraSection>
          <ResumePreviewLibraEmployment item={employmentsItems[0]} />
        </>
      );
      employmentsItems?.slice(1).map((item) => {
        nodes.push(<ResumePreviewLibraEmployment item={item} />);
      });
    }
    if (
      section === 'internships' &&
      resume?.internships?.title &&
      internshipsItems?.length &&
      internshipsItems?.length > 0
    ) {
      nodes.push(
        <>
          <ResumePreviewLibraSection section="internships">{resume?.internships?.title}</ResumePreviewLibraSection>
          <ResumePreviewLibraEmployment item={internshipsItems[0]} />
        </>
      );
      internshipsItems?.slice(1).map((item) => {
        nodes.push(<ResumePreviewLibraEmployment item={item} />);
      });
    }
    if (section === 'projects' && resume?.projects?.title && projectsItems?.length && projectsItems?.length > 0) {
      nodes.push(
        <>
          <ResumePreviewLibraSection section="projects">{resume?.projects?.title}</ResumePreviewLibraSection>
          <ResumePreviewLibraProject item={projectsItems[0]} />
        </>
      );
      projectsItems?.slice(1).map((item) => {
        nodes.push(<ResumePreviewLibraProject item={item} />);
      });
    }
    if (section === 'custom') {
      const title = resume?.[key]?.title;
      const customsItems = resume?.[key]?.items;
      const customsItemsFormatted = customsItems?.map((item) => ({
        ...item,
        highlights: item?.highlights?.map((highlight) => serializeSlateNodeToHtml(highlight)),
      }));
      nodes.push(
        <>
          <ResumePreviewLibraSection section="custom">{title}</ResumePreviewLibraSection>
          <ResumePreviewLibraCustom item={customsItemsFormatted[0]} />
        </>
      );
      customsItemsFormatted?.slice(1).map((item) => {
        nodes.push(<ResumePreviewLibraCustom item={item} />);
      });
    }
    if (
      section === 'educations' &&
      resume?.educations?.title &&
      educationsItems?.length &&
      educationsItems?.length > 0
    ) {
      const title = resume?.educations?.title || '';
      nodes.push(
        <>
          <ResumePreviewLibraSection section="educations">{title}</ResumePreviewLibraSection>
          <ResumePreviewLibraEducation item={educationsItems[0]} />
        </>
      );
      educationsItems?.slice(1).map((item) => {
        nodes.push(<ResumePreviewLibraEducation item={item} />);
      });
    }
    if (
      section === 'certifications' &&
      resume?.certifications?.title &&
      certificationsItems?.length &&
      certificationsItems?.length > 0
    ) {
      nodes.push(
        <ResumePreviewLibraCertifications title={resume?.certifications?.title} items={certificationsItems} />
      );
    }
    if (section === 'languages' && resume?.languages?.title && languagesItems?.length && languagesItems?.length > 0) {
      nodes.push(<ResumePreviewLibraLanguages title={resume?.languages?.title} items={languagesItems} />);
    }
    if (section === 'hobbies' && resume?.hobbies?.title && languagesItems?.length && languagesItems?.length > 0) {
      nodes.push(<ResumePreviewLibraHobby title={resume?.hobbies?.title} content={hobbyItem} />);
    }
    if (section === 'skills' && resume?.skills?.title && skillsItems?.length && skillsItems?.length > 0) {
      nodes.push(<ResumePreviewLibraSkills title={resume?.skills?.title} items={skillsItems} />);
    }
    if (
      section === 'references' &&
      resume?.references?.title &&
      referencesItems?.length &&
      referencesItems?.length > 0
    ) {
      nodes.push(
        <ResumePreviewLibraReferences
          onDemand={resume?.references?.onDemand}
          title={resume?.references?.title}
          items={referencesItems}
        />
      );
    }
    if (section === 'links' && resume?.links?.title && linksItems?.length && linksItems?.length > 0) {
      nodes.push(<ResumePreviewLibraLinks title={resume?.links?.title} items={linksItems} />);
    }
    if (section === 'pagebreak') {
      nodes.push(<ResumePreviewLibraPageBreak />);
    }
  });

  return nodes;
};
