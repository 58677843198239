import { ResumeStoreState } from '../../../routes/Resume/Store';

import { ResumePreviewAriesSummary } from './ResumePreviewAries.styles';
import { serializeSlateNodeToHtml } from '../SlateEditor/helper';
import {
  ResumePreviewCancerBasic,
  ResumePreviewCancerCertifications,
  ResumePreviewCancerContact,
  ResumePreviewCancerEducation,
  ResumePreviewCancerEmployment,
  ResumePreviewCancerExtra,
  ResumePreviewCancerHobby,
  ResumePreviewCancerLanguages,
  ResumePreviewCancerLinks,
  ResumePreviewCancerPageBreak,
  ResumePreviewCancerProject,
  ResumePreviewCancerReferences,
  ResumePreviewCancerSection,
  ResumePreviewCancerSkills,
} from './ResumePreviewCancer.styles';

export const ResumePreviewCancer = (state: ResumeStoreState) => {
  const { resume, additionalSections, design } = state;
  const { hasParagraphIndendation = false, paragraphTextAlignment = 'justify' } = design;

  const linkedinUrl = resume?.links?.items?.filter((item) => item?.type === 'linkedin').pop()?.url || '';
  const githubUrl = resume?.links?.items?.filter((item) => item?.type === 'github').pop()?.url || '';
  const fullAddress =
    resume?.contacts?.city && resume?.contacts?.country
      ? `${resume?.contacts?.city}, ${resume?.contacts?.country}`
      : resume?.contacts?.city
      ? resume?.contacts?.city
      : resume?.contacts?.country;
  const summary = resume?.summary ? serializeSlateNodeToHtml(resume?.summary) : '';
  const employmentsItems = resume?.employments?.items
    .filter((item) => item?.company && item?.company !== '')
    .map((item) => ({
      ...item,
      highlights: serializeSlateNodeToHtml(item?.highlights),
    }));
  const internshipsItems = resume?.internships?.items
    .filter((item) => item?.company && item?.company !== '')
    .map((item) => ({
      ...item,
      highlights: serializeSlateNodeToHtml(item?.highlights),
    }));
  const educationsItems = resume?.educations?.items
    .filter((item) => item?.institution && item?.institution !== '')
    .map((item) => ({
      ...item,
      highlights: serializeSlateNodeToHtml(item?.highlights),
    }));
  const projectsItems = resume?.projects?.items
    .filter((item) => item?.name && item?.name !== '')
    .map((item) => ({
      ...item,
      highlights: serializeSlateNodeToHtml(item?.highlights),
    }));
  const certificationsItems = resume?.certifications?.items
    .filter((item) => item?.title && item?.title !== '')
    .map((item) => ({
      ...item,
    }));
  const languagesItems = resume?.languages?.items
    .filter((item) => item?.language && item?.language !== '')
    .map((item) => ({
      ...item,
    }));
  const hobbyItem = serializeSlateNodeToHtml(resume?.hobbies?.content);
  const skillsItems = resume?.skills?.items
    .filter((item) => item?.name && item?.name !== '')
    .map((item) => ({
      ...item,
    }));
  const referencesItems = resume?.references?.items
    .filter((item) => item?.name && item?.name !== '')
    .map((item) => ({
      ...item,
    }));
  const linksItems = resume?.links?.items
    ?.filter((item) => !['linkedin', 'github'].includes(item?.type))
    .map((item) => ({
      ...item,
    }));

  const headerNodes = [
    <ResumePreviewCancerBasic
      jobTitle={resume?.basics?.jobTitle}
      name={`${resume?.basics?.firstName} ${resume?.basics?.lastName}`}
    />,

    <ResumePreviewAriesSummary
      hasParagraphIndendation={hasParagraphIndendation}
      paragraphTextAlignment={paragraphTextAlignment}
    >
      {summary}
    </ResumePreviewAriesSummary>,
  ];

  const sidebarNodes: any = [
    <ResumePreviewCancerSection>Contact</ResumePreviewCancerSection>,
    <ResumePreviewCancerContact
      fullAddress={fullAddress}
      githubUrl={githubUrl}
      linkedinUrl={linkedinUrl}
      email={resume?.contacts?.email}
      phone={resume?.contacts?.phone}
    />,
    <ResumePreviewCancerExtra nationality={resume?.basics?.nationality} />,
    <ResumePreviewCancerExtra nationality={resume?.basics?.drivingLicense} />,
    <ResumePreviewCancerExtra nationality={resume?.basics?.nationality} />,
    <ResumePreviewCancerExtra dateOfBirth={resume?.basics?.dateOfBirth} placeOfBirth={resume?.basics?.placeOfBirth} />,
    <ResumePreviewCancerExtra workRightEU={resume?.basics?.workRightEU} workRightUK={resume?.basics?.workRightUK} />,
    <ResumePreviewCancerExtra availability={resume?.basics?.availability} />,
  ];

  const mainNodes: any = [];

  additionalSections?.map(({ key, section }) => {
    if (
      section === 'employments' &&
      resume?.employments?.title &&
      employmentsItems?.length &&
      employmentsItems?.length > 0
    ) {
      mainNodes.push(
        <>
          <ResumePreviewCancerSection>{resume?.employments?.title}</ResumePreviewCancerSection>
          <ResumePreviewCancerEmployment item={employmentsItems[0]} />
        </>
      );
      employmentsItems?.slice(1).map((item) => {
        mainNodes.push(<ResumePreviewCancerEmployment item={item} />);
      });
    }
    if (
      section === 'internships' &&
      resume?.internships?.title &&
      internshipsItems?.length &&
      internshipsItems?.length > 0
    ) {
      mainNodes.push(
        <>
          <ResumePreviewCancerSection>{resume?.internships?.title}</ResumePreviewCancerSection>
          <ResumePreviewCancerEmployment item={internshipsItems[0]} />
        </>
      );
      internshipsItems?.slice(1).map((item) => {
        mainNodes.push(<ResumePreviewCancerEmployment item={item} />);
      });
    }
    if (section === 'projects' && resume?.projects?.title && projectsItems?.length && projectsItems?.length > 0) {
      mainNodes.push(
        <>
          <ResumePreviewCancerSection>{resume?.projects?.title}</ResumePreviewCancerSection>
          <ResumePreviewCancerProject item={projectsItems[0]} />
        </>
      );
      projectsItems?.slice(1).map((item) => {
        mainNodes.push(<ResumePreviewCancerProject item={item} />);
      });
    }
    if (
      section === 'educations' &&
      resume?.educations?.title &&
      educationsItems?.length &&
      educationsItems?.length > 0
    ) {
      const title = resume?.educations?.title || '';
      mainNodes.push(
        <>
          <ResumePreviewCancerSection>{title}</ResumePreviewCancerSection>
          <ResumePreviewCancerEducation item={educationsItems[0]} />
        </>
      );
      educationsItems?.slice(1).map((item) => {
        mainNodes.push(<ResumePreviewCancerEducation item={item} />);
      });
    }
    if (
      section === 'certifications' &&
      resume?.certifications?.title &&
      certificationsItems?.length &&
      certificationsItems?.length > 0
    ) {
      mainNodes.push(
        <ResumePreviewCancerCertifications title={resume?.certifications?.title} items={certificationsItems} />
      );
    }
    if (section === 'languages' && resume?.languages?.title && languagesItems?.length && languagesItems?.length > 0) {
      sidebarNodes.push(<ResumePreviewCancerLanguages title={resume?.languages?.title} items={languagesItems} />);
    }
    if (section === 'hobbies' && resume?.hobbies?.title && languagesItems?.length && languagesItems?.length > 0) {
      sidebarNodes.push(<ResumePreviewCancerHobby title={resume?.hobbies?.title} content={hobbyItem} />);
    }
    if (section === 'skills' && resume?.skills?.title && skillsItems?.length && skillsItems?.length > 0) {
      sidebarNodes.push(<ResumePreviewCancerSkills title={resume?.skills?.title} items={skillsItems} />);
    }
    if (
      section === 'references' &&
      resume?.references?.title &&
      referencesItems?.length &&
      referencesItems?.length > 0
    ) {
      sidebarNodes.push(
        <ResumePreviewCancerReferences
          onDemand={resume?.references?.onDemand}
          title={resume?.references?.title}
          items={referencesItems}
        />
      );
    }
    if (section === 'links' && resume?.links?.title && linksItems?.length && linksItems?.length > 0) {
      mainNodes.push(<ResumePreviewCancerLinks title={resume?.links?.title} items={linksItems} />);
    }
    if (section === 'pagebreak') {
      mainNodes.push(<ResumePreviewCancerPageBreak />);
    }
  });

  return { headerNodes, sidebarNodes, mainNodes };
};
