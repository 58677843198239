import { ResumeStoreState } from '../../../routes/Resume/Store';

import { serializeSlateNodeToHtml } from '../SlateEditor/helper';
import {
  ResumePreviewTaurusBasic,
  ResumePreviewTaurusCertifications,
  ResumePreviewTaurusContact,
  ResumePreviewTaurusCustom,
  ResumePreviewTaurusEducation,
  ResumePreviewTaurusEmployment,
  ResumePreviewTaurusExtra,
  ResumePreviewTaurusHobby,
  ResumePreviewTaurusLanguages,
  ResumePreviewTaurusLinks,
  ResumePreviewTaurusPageBreak,
  ResumePreviewTaurusProject,
  ResumePreviewTaurusReferences,
  ResumePreviewTaurusSection,
  ResumePreviewTaurusSkills,
  ResumePreviewTaurusSummary,
} from './ResumePreviewTaurus.styles';

export const ResumePreviewTaurus = (state: ResumeStoreState) => {
  const { resume, additionalSections, design } = state;
  const { hasParagraphIndendation = false, paragraphTextAlignment = 'justify' } = design;

  const linkedinUrl = resume?.links?.items?.filter((item) => item?.type === 'linkedin').pop()?.url || '';
  const githubUrl = resume?.links?.items?.filter((item) => item?.type === 'github').pop()?.url || '';
  const fullAddress =
    resume?.contacts?.city && resume?.contacts?.country
      ? `${resume?.contacts?.city}, ${resume?.contacts?.country}`
      : resume?.contacts?.city
      ? resume?.contacts?.city
      : resume?.contacts?.country;
  const summary = resume?.summary ? serializeSlateNodeToHtml(resume?.summary) : '';
  const employmentsItems = resume?.employments?.items
    .filter((item) => item?.company && item?.company !== '')
    .map((item) => ({
      ...item,
      highlights: serializeSlateNodeToHtml(item?.highlights),
    }));
  const internshipsItems = resume?.internships?.items
    .filter((item) => item?.company && item?.company !== '')
    .map((item) => ({
      ...item,
      highlights: serializeSlateNodeToHtml(item?.highlights),
    }));
  const educationsItems = resume?.educations?.items
    .filter((item) => item?.institution && item?.institution !== '')
    .map((item) => ({
      ...item,
      highlights: serializeSlateNodeToHtml(item?.highlights),
    }));
  const projectsItems = resume?.projects?.items
    .filter((item) => item?.name && item?.name !== '')
    .map((item) => ({
      ...item,
      highlights: serializeSlateNodeToHtml(item?.highlights),
    }));
  const certificationsItems = resume?.certifications?.items
    .filter((item) => item?.title && item?.title !== '')
    .map((item) => ({
      ...item,
    }));
  const languagesItems = resume?.languages?.items
    .filter((item) => item?.language && item?.language !== '')
    .map((item) => ({
      ...item,
    }));
  const hobbyItem = serializeSlateNodeToHtml(resume?.hobbies?.content);
  const skillsItems = resume?.skills?.items
    .filter((item) => item?.name && item?.name !== '')
    .map((item) => ({
      ...item,
    }));
  const referencesItems = resume?.references?.items
    .filter((item) => item?.name && item?.name !== '')
    .map((item) => ({
      ...item,
    }));
  const linksItems = resume?.links?.items
    ?.filter((item) => !['linkedin', 'github'].includes(item?.type))
    .map((item) => ({
      ...item,
    }));

  const nodes = [
    <ResumePreviewTaurusBasic
      jobTitle={resume?.basics?.jobTitle}
      name={`${resume?.basics?.firstName} ${resume?.basics?.lastName}`}
    />,

    <ResumePreviewTaurusContact
      email={resume?.contacts?.email}
      phone={resume?.contacts?.phone}
      fullAddress={fullAddress}
      linkedinUrl={linkedinUrl}
      githubUrl={githubUrl}
    />,

    <ResumePreviewTaurusExtra
      nationality={resume?.basics?.nationality}
      drivingLicense={resume?.basics?.drivingLicense}
      dateOfBirth={resume?.basics?.dateOfBirth}
      placeOfBirth={resume?.basics?.placeOfBirth}
      workRightEU={resume?.basics?.workRightEU}
      workRightUK={resume?.basics?.workRightUK}
      availability={resume?.basics?.availability}
    />,

    <ResumePreviewTaurusSummary
      hasParagraphIndendation={hasParagraphIndendation}
      paragraphTextAlignment={paragraphTextAlignment}
    >
      {summary}
    </ResumePreviewTaurusSummary>,
  ];

  additionalSections?.map(({ key, section }) => {
    if (
      section === 'employments' &&
      resume?.employments?.title &&
      employmentsItems?.length &&
      employmentsItems?.length > 0
    ) {
      nodes.push(
        <>
          <ResumePreviewTaurusSection>{resume?.employments?.title}</ResumePreviewTaurusSection>
          <ResumePreviewTaurusEmployment item={employmentsItems[0]} />
        </>
      );
      employmentsItems?.slice(1).map((item) => {
        nodes.push(<ResumePreviewTaurusEmployment item={item} />);
      });
    }
    if (
      section === 'internships' &&
      resume?.internships?.title &&
      internshipsItems?.length &&
      internshipsItems?.length > 0
    ) {
      nodes.push(
        <>
          <ResumePreviewTaurusSection>{resume?.internships?.title}</ResumePreviewTaurusSection>
          <ResumePreviewTaurusEmployment item={internshipsItems[0]} />
        </>
      );
      internshipsItems?.slice(1).map((item) => {
        nodes.push(<ResumePreviewTaurusEmployment item={item} />);
      });
    }
    if (section === 'projects' && resume?.projects?.title && projectsItems?.length && projectsItems?.length > 0) {
      nodes.push(
        <>
          <ResumePreviewTaurusSection>{resume?.projects?.title}</ResumePreviewTaurusSection>
          <ResumePreviewTaurusProject item={projectsItems[0]} />
        </>
      );
      projectsItems?.slice(1).map((item) => {
        nodes.push(<ResumePreviewTaurusProject item={item} />);
      });
    }
    if (section === 'custom') {
      const title = resume?.[key]?.title;
      const customsItems = resume?.[key]?.items;
      const customsItemsFormatted = customsItems?.map((item) => ({
        ...item,
        highlights: item?.highlights?.map((highlight) => serializeSlateNodeToHtml(highlight)),
      }));
      nodes.push(
        <>
          <ResumePreviewTaurusSection>{title}</ResumePreviewTaurusSection>
          <ResumePreviewTaurusCustom item={customsItemsFormatted[0]} />
        </>
      );
      customsItemsFormatted?.slice(1).map((item) => {
        nodes.push(<ResumePreviewTaurusCustom item={item} />);
      });
    }
    if (
      section === 'educations' &&
      resume?.educations?.title &&
      educationsItems?.length &&
      educationsItems?.length > 0
    ) {
      const title = resume?.educations?.title || '';
      nodes.push(
        <>
          <ResumePreviewTaurusSection>{title}</ResumePreviewTaurusSection>
          <ResumePreviewTaurusEducation item={educationsItems[0]} />
        </>
      );
      educationsItems?.slice(1).map((item) => {
        nodes.push(<ResumePreviewTaurusEducation item={item} />);
      });
    }
    if (
      section === 'certifications' &&
      resume?.certifications?.title &&
      certificationsItems?.length &&
      certificationsItems?.length > 0
    ) {
      nodes.push(
        <ResumePreviewTaurusCertifications title={resume?.certifications?.title} items={certificationsItems} />
      );
    }
    if (section === 'languages' && resume?.languages?.title && languagesItems?.length && languagesItems?.length > 0) {
      nodes.push(<ResumePreviewTaurusLanguages title={resume?.languages?.title} items={languagesItems} />);
    }
    if (section === 'hobbies' && resume?.hobbies?.title && languagesItems?.length && languagesItems?.length > 0) {
      nodes.push(<ResumePreviewTaurusHobby title={resume?.hobbies?.title} content={hobbyItem} />);
    }
    if (section === 'skills' && resume?.skills?.title && skillsItems?.length && skillsItems?.length > 0) {
      nodes.push(<ResumePreviewTaurusSkills title={resume?.skills?.title} items={skillsItems} />);
    }
    if (
      section === 'references' &&
      resume?.references?.title &&
      referencesItems?.length &&
      referencesItems?.length > 0
    ) {
      nodes.push(
        <ResumePreviewTaurusReferences
          onDemand={resume?.references?.onDemand}
          title={resume?.references?.title}
          items={referencesItems}
        />
      );
    }
    if (section === 'links' && resume?.links?.title && linksItems?.length && linksItems?.length > 0) {
      nodes.push(<ResumePreviewTaurusLinks title={resume?.links?.title} items={linksItems} />);
    }
    if (section === 'pagebreak') {
      nodes.push(<ResumePreviewTaurusPageBreak />);
    }
  });

  return nodes;
};
